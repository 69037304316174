/*
 * @description : 项目的入口文件
 * @author : maskim
 * @date : 2021-07-28 14:52:58
 * @lastTime : 2022-06-14 11:48:38
 * @LastAuthor : Do not edit
 * @文件路径 : /ces项目模板/src/index.js
 */
// 习惯采用less，引入需要用到less代码入口。注意不支持bg自动计算尺寸、不支持动态数学计算
import "./less/index.less";
import FirstLoadingUI from "./loading/FirstLoadingUI"; // 首屏加载界面，如果是animate 的 pixi，那这个dom使用与pixi一致的画面可以减少白屏情况
import { loader } from "ces";
import { SiteModelBase, WeChatShareModel } from "ces-mini-h5";

// 设置缓存版本。js逻辑代码、资源加载等都会跟随这个缓存版本号来
const { Loader } = loader;
Loader.cacheVersion = "0.0.13";
//  获取package.json上设置项目路径
const pkg = require("../package.json");

/**
 * 站点模块
 *
 * @author maksim
 * @date 06/08/2021/  11:47:01
 * @class CagoeSiteModel
 * @extends {SiteModelBase}
 */
class CagoeSiteModel extends SiteModelBase {
	constructor() {
		super();
		// 初始化分享,这里写是为了可以更早处理分享模块构建
		// this.initializeShare();
		// 初始化模块
		this.initialize();
	}

	/**
	 * 初始化
	 *
	 * @author maksim
	 * @date 04/08/2021/  19:16:18
	 * @memberof CagoeSiteModel
	 */
	async initialize() {
		// TODO: 设置默认的第一个页面。确保有这个页面，并且资源加载
		this.setDefaultPage("KnowledgeDetailPage");

		// 记录項目文件夾路徑
		this.projectDirectory = "./" + pkg.projectFolder + "/";
		this.path = this.projectDirectory;

        // 缓存版本号
		this.LoaderCacheVersion = Loader.cacheVersion;

		// 初始化loading界面【推荐loading采用dom的方式】
		this.initializeLoadPanel();
        this.hideProgress()
		// this.showProgress(5);

		// TODO: 加载threejs pixijs框架。默认不需要，我们还是希望把框架打包进入主文件减少js请求数
		// await this.loadOtherLib();

		// 载入站点入口
		await this.loadSiteAppEntry();
		// 显示进度
		// this.showProgress(20);

		// 显示场景dom元素根容器，为了避免显示失败情况下处理
		this.siteScene.show();
	}
	/**
	 * 考虑编译打包 threejs 与  pixijs框架会很慢，还是直接插入页面加载
	 *
	 * @author maksim
	 * @date 08/06/2022/  20:43:21
	 * @returns {Promise}
	 */
	loadOtherLib() {
		return new Promise((resolve) => {
			let _arr = ["js/pixi.min.js", "js/animate.min.js"];
			let _loadList = [];
			for (let i = 0; i < _arr.length; i++) {
				let _url = this.projectDirectory + _arr[i];
				let _loader = new loader.JSLoader({ url: _url });
				_loadList.push(_loader);
				_loader.on("complete", () => {
					complete();
				});
				_loader.load();
			}
			let _index = 0;
			function complete() {
				_index++;
				if (_index >= _arr.length) {
					resolve(true);
				}
			}
		});
	}

	/**
	 * loading界面
	 *
	 * @author maksim
	 * @date 06/08/2021/  11:47:16
	 * @memberof CagoeSiteModel
	 */
	initializeLoadPanel() {
		this.siteScene.show(); // 显示场景dom元素根容器
		this.mainLoadUIBox = $("#load-main-panel");
		this.mainLoadUIBox.show();

		// 构建loadingUI 对象
		this.firstLoadingUI = new FirstLoadingUI(this, {
			color: "#000", // loading颜色
			background: "#fff", // loading背景色
		});
	}

	/**
	 * 程序入口js加载
	 *
	 * @author maksim
	 * @date 06/08/2021/  11:47:24
	 * @returns {*}
	 * @memberof CagoeSiteModel
	 */
	loadSiteAppEntry() {
		return import(/* webpackChunkName: "site_app_entry" */ "./site_app_entry.js");
	}

	/**
	 * 进度控制
	 *
	 * @author maksim
	 * @date 06/08/2021/  11:47:34
	 * @param {*} ps
	 * @memberof CagoeSiteModel
	 */
	showProgress(ps) {
		this.firstLoadingUI.setProgress(ps);
	}
	/**
	 *  进度隐藏
	 *
	 * @author maksim
	 * @date 06/08/2021/  11:47:39
	 * @memberof CagoeSiteModel
	 */
	hideProgress() {
		this.firstLoadingUI.hide();
	}

	/**
	 *  初始化分享模块
	 *
	 * @author maksim
	 * @date 06/08/2021/  12:06:52
	 * @memberof APPModel
	 */
	initializeShare() {
		let _shareModel = new WeChatShareModel({
			title: "速速提供分享标题",
			info: "速速提供分享文案",
			timelineInfo: "速速提供分享文案",
			shareUrl: "./index.aspx",
			workUrl: "./index.aspx#/WorkPage?workID=",
			imageUrl: `./${pkg.projectFolder}/images/ShareImg.jpg`,
			wxJsUrl: `./${pkg.projectFolder}/js/cagoeShare.js`,
			apiUrl: "//wechat.cagoe.com/JsApiWXConfig.aspx", // TODO: 这公司默认分享接口，如果用客户分享，需要替换下这个路径
			shareCallBack: () => {
				// alert('触发分享');
			},
		});
		_shareModel.on("shareAPIEnd", (e) => {
			console.log("shareAPIEnd", e);
		});
		_shareModel.on("shareAPIError", (e) => {
			console.log("shareAPIError");
		});
		_shareModel.on("setShareEnd", () => {
			console.log("setShareEnd");
		});
		// 设置默认分享
		_shareModel.defaultWeiShare();
		this.shareModel = _shareModel;
	}
}

window.SiteModel = new CagoeSiteModel();
