/*
 * @description : 首屏loading界面,一般考虑到dom页面加载会更快所以采用dom方式做loading，pixijs动画框架就要非常大不适合做首屏loading
 * @author : maskim
 * @date : 2021-09-15 21:10:09
 * @lastTime : 2022-06-14 11:46:41
 * @LastAuthor : Do not edit
 * @文件路径 : /ces项目模板/src/loading/FirstLoadingUI.js
 */
/**
 * 首屏loading界面
 *
 * @author maksim
 * @date 15/09/2021/  21:35:29
 * @class FirstLoadingUI
 */
class FirstLoadingUI {
    /**
     * 构建函数
     * @author maksim
     * @date 15/09/2021/  21:35:45
     * @param {*} siteModel 由于SiteModel还没构建好，所以在这个UI创建时候传入
     * @param {object} opts 配置
     * - opts.color =  '#FF6700';
     * - opts.background= '#2B3134'
     * - opts.prefix= 'loading'
     * @memberof FirstLoadingUI
     */
    constructor(siteModel, opts = {}) {

        this.siteModel = siteModel;
        
        this.mainLoadUIBox = siteModel.mainLoadUIBox;
      
        const _domString = require('./firstLoadingUI.html').default;
        this.view = $(_domString).find('#firstLoadingPage');
        this.label = this.view.find('.loader-label');// 文本显示

        let _background = opts.background || '#2B3134';
        let _color = opts.color || '#FF6700';
        this._prefix = opts.prefix || 'loading'

        // 设置背景色
        this.view.css({
            background: _background
        });

        // 对svg设置颜色
        this.view.find('svg path').css({
            fill: _color
        });
        this.view.find('svg rect').css({
            fill: _color
        });
        // 设置loading 字体颜色
        this.label.css({
            color: _color
        })
        this.initialize();

    }
    /**
     * 初始化
     *
     * @author maksim
     * @date 15/09/2021/  21:36:37
     * @returns {*} 
     * @memberof FirstLoadingUI
     */
    initialize() {

        if (this._isInitializeComplete) return;
        this._isInitializeComplete = true;

        // 初始化显示
        this.show();

        this.siteModel.on('upsize', this.resize.bind(this));
        setTimeout(() => {
            this.resize();
        }, 200);

    }
    /**
     * 显示loading界面
     *
     * @author maksim
     * @date 15/09/2021/  21:36:44
     * @memberof FirstLoadingUI
     */
    show() {

        this._isShow = true;
        let _mainLoadUIBox = this.mainLoadUIBox;
        _mainLoadUIBox.show();
        _mainLoadUIBox.append(this.view);
        this.setProgress(0);
        this.resize();

    }

    /**
     * 隐藏loading界面
     *
     * @author maksim
     * @date 15/09/2021/  21:36:53
     * @memberof FirstLoadingUI
     */
    hide() {
        
        this._isShow = false;
        let _mainLoadUIBox = this.mainLoadUIBox;
        _mainLoadUIBox.hide();
        this.view.remove();

    }

    /**
     * 设置进度
     *
     * @author maksim
     * @date 15/09/2021/  21:37:05
     * @param {*} val
     * @memberof FirstLoadingUI
     */
    setProgress(val) {
        let _progress = Math.min(val >> 0, 100);
        let _label = _progress < 10 ? `${this._prefix} 0${_progress}%` : `${this._prefix} ${_progress}%`;
        this.label.text(_label);
    }
    /**
     * 自适应
     *
     * @author maksim
     * @date 15/09/2021/  21:37:12
     * @returns {*} 
     * @memberof FirstLoadingUI
     */
    resize() {
        if (!this.siteModel) return;
        if (!this._isShow) return;
        let _w = this.siteModel.width;
        let _h = this.siteModel.height;
        this.view.css({ height: _h });
    }
}


export default FirstLoadingUI;